import React, { useEffect, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import DivisionCard from "../../components/DivisionCard";
import NavigateBlock from "../../components/NavigateBlock";
import { LogoAnimationContext } from "../../context/LogoAnimationContext";
import ogIMage from "../../images/og.png";

const DivisionPage = ({ location }) => {
    const { setCurrentAnimationName } = useContext(LogoAnimationContext);

    useEffect(() => {
        setCurrentAnimationName("division_transition");
        return () => {
            setCurrentAnimationName("division_transition");
        };
    }, []);

    const sedinDivisions = useStaticQuery(graphql`
        {
            allContentfulDivisions(
                filter: { subDivision: { eq: false }, showOnViewport: { eq: true } }
                sort: { fields: foundedOn, order: ASC }
            ) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(quality: 100, placeholder: BLURRED)
                        title
                        file {
                            url
                        }
                    }
                    description {
                        description
                    }
                    slug
                    services {
                        title
                        id
                    }
                    members {
                        id
                        avatar {
                            gatsbyImageData(quality: 100, width: 40, placeholder: BLURRED)
                            title
                        }
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Seo
                title="Sedin Technologies - Divisions"
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
                description={
                    "Globally, Sedin’s diversified team of technologists, product leaders, and design thinkers have been solving our clients' most complex business problems."
                }
            />
            <section className="divisions-hero">
                <div className="container container--first">
                    <div className="text">
                        <div className="section-tag gap-2x">Divisions</div>
                        <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">
                            Meet our diversified team of technologists, product leaders, and design
                            thinkers.
                        </h1>
                    </div>
                </div>
            </section>
            <section className="divisions-wrapper">
                <div className="container container--pb">
                    {sedinDivisions.allContentfulDivisions.nodes.map((division) => (
                        <DivisionCard
                            key={division.id}
                            id={division.id}
                            logo={division.logo}
                            description={division.description.description}
                            slug={division.slug}
                            services={division.services}
                            members={division.members}
                        />
                    ))}
                </div>
            </section>
            <section className="division-services">
                <div className="container container--last">
                    <NavigateBlock />
                </div>
            </section>
        </Layout>
    );
};

export default DivisionPage;
