import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const DivisionCard = ({ logo, description, slug, services, members }) => {
    return (
        <div className="division-item">
            <div className="logo">
                {logo.gatsbyImageData ? (
                    <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} loading="lazy" />
                ) : (
                    <img src={logo.file.url} alt={logo.title} loading="lazy" />
                )}
            </div>
            <div className="description">{description}</div>
            {services && (
                <div className="services">
                    <span className="title">Services</span>
                    {services.map((service) => (
                        <span className="name" key={service.id}>
                            {service.title}
                        </span>
                    ))}
                </div>
            )}
            <div className="footer">
                <div className="team">
                    {members &&
                        members.map((member) => (
                            <span key={member.id}>
                                <GatsbyImage
                                    image={member.avatar.gatsbyImageData}
                                    alt={member.avatar.title}
                                    loading="lazy"
                                />
                            </span>
                        ))}
                </div>
                <Link to={`/divisions/${slug}/`} className="sdn-link">
                    Learn more
                </Link>
            </div>
        </div>
    );
};

export default DivisionCard;
