import React from "react";
import { Link } from "gatsby";

const NavigateBlock = ({ header, description, buttonText, buttonLink }) => {
    return (
        <div className="navigate-block">
            <div className="navigate-block__content text">
                <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                    {header || "Navigate the future with next-gen solutions."}
                </h2>
                <p className="text-p2 text-fw-regular text-clr-secondary gap-3x">
                    {description ||
                        "In the age of infinite leverage, your business needs a combination of design-led transformation, data-driven insights, and unified systems."}
                </p>
                <Link to={buttonLink ? `/${buttonLink}` : "/services"} className="sdn-cta-btn">
                    {buttonText || "Explore our services"}
                </Link>
            </div>
        </div>
    );
};

export default NavigateBlock;
